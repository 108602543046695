import React, { useEffect, useState } from "react";
import useInterval from "./useInterval";
import Plot from 'react-plotly.js';

const startTime = Date.now();
const NODEV = "------------";
const NOTEMP = "--.--";

function formatDate(n) {
  n = Math.floor(n/1000);
  const day = Math.floor(n / (24 * 3600));
  n = n % (24 * 3600);
  var hour = '' + Math.floor(n / 3600);
  n %= 3600;
  var minute =  '' + Math.floor(n / 60) ;
  n %= 60;
  var seconds =  '' + n;
  if (hour.length < 2)  hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (seconds.length < 2) seconds = '0' + seconds;
  return [day+"d", hour+"h", minute+"m", seconds+"s"].join(' ');
}

const layout = {
            dragmode: 'pan',
            plot_bgcolor: 'rgba(0, 0, 0, 0)', // Set the plot background color to transparent
            paper_bgcolor: 'rgba(0, 0, 0, 0)', // Set the paper background color to transparent
          
            margin: {
              l: 0,
              r: 0,
              t: 0,
              b: 0
            },

            xaxis: {
              gridcolor: 'rgb(128, 128, 128)',
              //tickformat: '%d %B (%a)',
              //type: 'date',
              //showgrid: false,
              zeroline: false,
              //range: [1, 200]
            },
            yaxis: {
              gridcolor: 'rgb(128, 128, 128)',
              //showgrid: false,
              zeroline: false,
            },
          };


const App = () => {
  const [port, setPort] = useState(null);
  const [writer, setWriter] = useState(null);
  const [reader, setReader] = useState(null);

  const [info, setInfo] = useState(NODEV);
  const [present, setPresent] = useState(false);

  const [uptime, setUptime] = useState(null);
  const [temp, setTemp] = useState(NOTEMP);
  const [historyX, setHistoryX] = useState([]);
  const [historyY, setHistoryY] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [selectedTimerValue, setSelectedTimerValue] = useState("5");

  const addHistory = (setHistory, newElement) => {
    setHistory(prevHistory => {
        if (prevHistory.length >= 180) {
            // Удаляем первый элемент и добавляем новый в конец массива
            return [...prevHistory.slice(1), newElement];
        } else {
            // Просто добавляем новый элемент в конец массива
            return [...prevHistory, newElement];
        }
    });
  };

  useInterval(() => {
    setUptime(formatDate(Date.now() - startTime));
  }, 1000);

  useInterval(() => {
    sendMessage("~G");
  }, parseInt(selectedTimerValue)*1000);

  const connectPort = async (force) => {
    let newPort;
    if (force) {
      try {
        newPort = await navigator.serial.requestPort();
        setPort(newPort);
      } catch (error) {
        setPresent(false);
        setPort(null);
        console.error("There was an error opening the port: ", error);
        return
      }
    }
    if ('serial' in navigator) {
      const ports = await navigator.serial.getPorts();
      if (ports.length === 0) {
       
        try {
          newPort = await navigator.serial.requestPort();
        } catch (error) {
          setPresent(false);
          setPort(null);
          console.error("There was an error opening the port: ", error);
          return
        }
        setPort(newPort);
      } else {
        setPort(ports[0]);
      }
    }
  };

  //console.log(port);
  const sendMessage = async (message) => {
    if (port && writer) {
      const data = new TextEncoder().encode(message);
      try {
        await writer.write(data);
      } catch (error) {
        setPresent(false);
        setPort(null);
      }
     
    }
  };

  const readLoop = async () => {
    try {
      while (reader) {
        const { value, done } = await reader.read();
        if (value) {
          const data = new TextDecoder().decode(value);
          if (!data.startsWith('~') || !data.endsWith('\n')) { 
            console.log("skip broken packet");
          } else if (data.startsWith('~I')) {
            const s = data.slice(2).split(' ');
            if (s.length === 5) {
              setInfo(s[0]+' '+s[4])
            }
            setPresent(true);
          } else if (data.startsWith('~G'))  {
            setTemp(data.slice(2));
            addHistory(setHistoryX, new Date());
            addHistory(setHistoryY, parseInt(data.slice(2)));
          }
        }
        if (done) {
          reader.releaseLock();
          break;
        }
      }
    } catch (error) {
      setPort(null);
      setPresent(false);
    }
  };

   useEffect(() => {
    if ((port) && (reader)) {
      sendMessage("~I");
      readLoop();
    }
  }, [port, reader]);

  useEffect(() => {
      connectPort(false);
  }, []);

  useEffect(() => {
    setDataset([{
      x: historyX,
      y: historyY,
      type: 'scatter',
      mode: 'lines+markers',
    }]);
  }, [historyX]);


  useEffect(() => {
    if (port) {
      (async () => {
        try {
          await port.open({ baudRate: 9600 });
         
        } catch (error) {
          return;
        }
        const writer = port.writable.getWriter();
        const reader = port.readable.getReader();
        setWriter(writer);
        setReader(reader);
      })();
    }
  }, [port]);

  return ( <div className="bg-repeat bg-base-200 p-6 w-full h-screen" style={{backgroundImage: "radial-gradient(hsla(var(--bc) /.2) .5px, hsla(var(--b2) /1) .5px)", backgroundSize: "5px 5px"}}>
            <div className="card w-full bg-base-100 h-12 rounded-xl shadow-xl flex flex-row items-center">
                  <p className="ml-8">{present?info:NODEV}</p>        

                  <div className="flex items-center justify-center ml-8">
                      <span className="flex absolute h-4 w-4">
                        { present && <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent opacity-75"></span> }
                        <span className="relative inline-flex rounded-full h-4 w-4 bg-accent"></span>
                      </span>
                  </div>

                  <div className="grow"/>
   

                  <div className={"space-x-4 flex flex-row mr-8"}>
                    <p className="">{uptime}</p> 
                  </div>
       
                  <select 
                      value={selectedTimerValue} 
                      onChange={(event) => setSelectedTimerValue(event.target.value)}
                      className="select select-sm max-w-xs select-bordered select-accent"
                  >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="60">60</option>
                      <option value="120">120</option>
                  </select> 
                  <div className="ml-2">s</div>

                  <button className="ml-4 btn btn-accent btn-outline btn-sm" onClick={()=>{setHistoryY([]);setHistoryX([]);setTemp(NOTEMP);}}>
                    &nbsp;&nbsp;&nbsp;Clear&nbsp;&nbsp;&nbsp;
                  </button>
                  <button className="ml-4 btn btn-accent btn-outline btn-sm" onClick={()=>connectPort(true)}>
                    Connect
                  </button>
              </div>
              <div className="w-full bg-base-100 rounded-xl shadow-xl mt-6 p-4">
                <Plot data={dataset} layout={layout} className="mt-4 w-full h-96"/>
                <div className="w-full text-center text-3xl mt-6">
                  {present?temp+'°C':NOTEMP}
                </div>
              </div>
            </div>

  );
};

export default App;